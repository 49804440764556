import React from "react";
import styled, { css } from "styled-components";

import device from "../../styles/media-queries";

import Technology from "./technology";
import ReactIcon from "../../images/react-icon.inline.svg";
import TypescriptIcon from "../../images/typescript-icon.inline.svg";
import CSSModulesIcon from "../../images/css-modules-icon.inline.svg";
import SASSIcon from "../../images/sass-icon.inline.svg";
import JavascriptIcon from "../../images/javascript-icon.inline.svg";
import ReduxIcon from "../../images/redux-icon.inline.svg";
import GatsbyIcon from "../../images/gatsby-icon.inline.svg";
import NextJSIcon from "../../images/nextjs-icon.inline.svg";
import NodeJSIcon from "../../images/nodejs-icon.inline.svg";
import WebpackIcon from "../../images/webpack-icon.inline.svg";
import JestIcon from "../../images/jest-icon.inline.svg";
import ScreenReaderOnly from "../../components/screen-reader-only";

const StyledSectionTechnologies = styled.section`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  > * {
    ${props =>
      props.mobileGrid &&
      css`
        flex: 0 0 ${100 / props.mobileGrid}%;
        width: ${100 / props.mobileGrid}%;
      `};

    ${props =>
      props.tabletGrid &&
      css`
        @media ${device.tablet} {
          flex: 0 0 ${100 / props.tabletGrid}%;
          width: ${100 / props.tabletGrid}%;
        }
      `};

    ${props =>
      props.desktopGrid &&
      css`
        @media ${device.tablet} {
          flex: 0 0 ${100 / props.desktopGrid}%;
          width: ${100 / props.desktopGrid}%;
        }
      `};
  }
`;

const SectionTechnologies = () => (
  <StyledSectionTechnologies desktopGrid={6}>
    <ScreenReaderOnly as="h2">
      Technologies which I use on daily basis
    </ScreenReaderOnly>
    <Technology icon={JavascriptIcon} />
    <Technology icon={ReactIcon} />
    <Technology icon={TypescriptIcon} />
    <Technology icon={CSSModulesIcon} />
    <Technology icon={SASSIcon} />
    <Technology icon={ReduxIcon} />
    <Technology icon={GatsbyIcon} />
    <Technology icon={NextJSIcon} />
    <Technology icon={NodeJSIcon} />
    <Technology icon={WebpackIcon} />
    <Technology icon={JestIcon} />
  </StyledSectionTechnologies>
);

export default SectionTechnologies;
