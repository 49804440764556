import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import colors from "../../styles/colors";
import { encode } from "../../utils";

import Button from "../../components/button";
import Input from "../../components/input";
import Textarea from "../../components/textarea";
import ReCaptcha from "../../components/recaptcha";
import ScreenReaderOnly from "../../components/screen-reader-only";
import Spinner from "../../images/spinner.inline.svg";

import device from "../../styles/media-queries";

const StyledContactForm = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 90rem;
  padding: 0 2rem;
  width: 100%;

  button {
    align-self: flex-end;
  }
`;

const StyledContactHeading = styled.h4`
  color: ${colors.primaryWhite};
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
  line-height: 2.2;
  text-align: center;
  width: 100%;

  @media ${device.tablet} {
    font-size: 4.6rem;
  }
`;

const StyledControlWrapper = styled.div`
  margin: 1.5rem 0;
  width: 100%;

  > * {
    max-width: 100%;
    width: 100%;
  }
`;

const StyledFormLabel = styled.label``;

const StyledSuccessMessage = styled.span`
  display: block;
  color: ${colors.primaryGreen};
  font-size: 2rem;
  line-height: 1.5;
  margin-top: 1.5rem;
  width: 100%;
  text-align: right;
`;

const StyledErrorMessage = styled.span`
  align-self: flex-start;
  color: ${colors.primaryWhite};
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
  font-style: italic;
  padding: 1rem 1.5rem;
  width: 100%;
`;

const StyledSpinner = styled(Spinner)`
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { handleSubmit, register, errors, reset } = useForm();

  async function onSubmit(values) {
    setLoading(true);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...values }),
    })
      .then(() => {
        setSuccess(true);
        setLoading(false);
        reset();
      })
      .catch(error => console.error(error.message) && setLoading(false));
  }

  return (
    <StyledContactForm
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      hidden
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledContactHeading>Say hello to me!</StyledContactHeading>

      <StyledControlWrapper>
        <StyledFormLabel htmlFor="name">
          <ScreenReaderOnly>Enter your name here</ScreenReaderOnly>
        </StyledFormLabel>
        <Input
          placeholder="Name"
          name="name"
          ref={register({ required: "Please enter your name." })}
          error={errors.name && errors.name.message}
          id="name"
        />

        {errors.name && (
          <StyledErrorMessage>{errors.name.message}</StyledErrorMessage>
        )}
      </StyledControlWrapper>

      <StyledControlWrapper>
        <StyledFormLabel htmlFor="email">
          <ScreenReaderOnly>Enter your email here</ScreenReaderOnly>
        </StyledFormLabel>
        <Input
          name="email"
          ref={register({
            required: "Please enter your e-mail address.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address.",
            },
          })}
          placeholder="E-mail"
          error={errors.email && errors.email.message}
          id="email"
        />

        {errors.email && (
          <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>
        )}
      </StyledControlWrapper>

      <StyledControlWrapper>
        <StyledFormLabel htmlFor="message">
          <ScreenReaderOnly>Enter your message here</ScreenReaderOnly>
        </StyledFormLabel>
        <Textarea
          rows={5}
          placeholder="Drop me a line!"
          name="message"
          ref={register({ required: "Please, fill this in. :-)" })}
          error={errors.message && errors.message.message}
          id="message"
        />

        {errors.message && (
          <StyledErrorMessage>{errors.message.message}</StyledErrorMessage>
        )}
      </StyledControlWrapper>

      <Button type="submit">{loading ? <StyledSpinner /> : "Submit"}</Button>
      {success && (
        <StyledSuccessMessage>
          Successfully sent a message! <br />I will be in touch with you
          shortly!
        </StyledSuccessMessage>
      )}
      <ReCaptcha />
    </StyledContactForm>
  );
};

export default ContactForm;
