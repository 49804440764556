import React from "react";
import styled from "styled-components";

import colors from "../styles/colors";

const StyledButton = styled.button.attrs({
  "aria-label": "Button to send a contact form",
})`
  background-color: ${colors.primaryBlue};
  border-radius: 3.3rem;
  border: 0;
  color: ${colors.primaryWhite};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  height: 6rem;
  line-height: 1;
  font-weight: 400;
  padding: 2rem 6.6rem;
  position: relative;
  text-align: center;
  transition: 0.4s all ease;
`;

const Button = ({ children, style }) =>
  children && <StyledButton style={style}>{children}</StyledButton>;

export default Button;
