import React from "react";

import styled from "styled-components";

const StyledTechnology = styled.svg`
  height: 12rem;
  margin: 2rem 0;
  padding: 2rem;
  width: 11rem;
`;

const Technology = ({ icon }) => <StyledTechnology as={icon} />;

export default Technology;
