import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

import colors from "../styles/colors";

const StyledTextArea = styled.textarea`
  background-color: transparent;
  border: 0.1rem solid ${colors.primaryWhite};
  border-radius: 3.3rem;
  color: ${colors.primaryWhite};
  font-size: 2.2rem;
  font-weight: 300;
  padding: 2.3rem 3.5rem 2rem 2rem;

  &::placeholder {
    color: ${colors.primaryWhite};
    font-size: 2.3rem;
    font-weight: 300;
  }

  ${props =>
    props.error &&
    css`
      border-color: ${colors.primaryRed};
    `}
`;

const TextArea = forwardRef(
  ({ name, id, placeholder, rows, cols, onChange, error }, ref) => (
    <StyledTextArea
      name={name && name}
      id={id && id}
      placeholder={placeholder && placeholder}
      rows={rows && rows}
      cols={cols && cols}
      onChange={onChange && onChange}
      ref={ref}
      error={error}
    />
  )
);

export default TextArea;
