import React, { Fragment } from "react";
import styled from "styled-components";

import SectionHeading from "./section-heading";

import device from "../styles/media-queries";

const StyledSection = styled.section`
  margin: 0 auto;
  max-width: 114rem;

  @media ${device.tablet} {
    margin: 0 auto 4rem auto;
    padding-top: 6rem;
  }
`;

const Section = ({
  BackgroundWrapper,
  children,
  name,
  id,
  inversedHeading,
}) => {
  const Wrapper = BackgroundWrapper ? BackgroundWrapper : Fragment;
  return (
    <Wrapper>
      <StyledSection id={id}>
        <SectionHeading name={name} inversedHeading={inversedHeading} />
        {children}
      </StyledSection>
    </Wrapper>
  );
};

export default Section;
