import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Section from "../sections/section";
import SectionExperience from "../sections/experience/section-experience";
import SectionTechnologies from "../sections/technologies/section-technologies";
import ContactForm from "../sections/contact/contact-form";

import ContactBackground from "../images/contact-background.svg";

const StyledContactBackground = styled.div`
  background: url(${ContactBackground}) no-repeat;
  background-position: top;
  height: 100%;
  margin-top: -5rem;
  padding-bottom: 5rem;
  padding-top: 11rem;
  margin-bottom: -35rem;
  width: 100%;
  z-index: -50;
`;

const IndexPage = () => (
  <Layout>
    <SEO />
    <Section name="Experience" id="experience">
      <SectionExperience />
    </Section>
    {/* <Section
      name="Projects"
      id="projects"
      BackgroundWrapper={StyledProjectsBackground}
    >
      <SectionProjects />
    </Section> */}
    <Section name="Technologies" id="technologies">
      <SectionTechnologies />
    </Section>
    <Section
      name="Contact me"
      id="contact"
      inversedHeading
      BackgroundWrapper={StyledContactBackground}
    >
      <ContactForm />
    </Section>
  </Layout>
);

export default IndexPage;
