import React from "react";
import styled from "styled-components";

import JobIcon from "../../images/job-icon.inline.svg";
import Tick from "../../images/tick.svg";

import colors from "../../styles/colors";
import device from "../../styles/media-queries";

const StyledExperience = styled.article`
  background-color: ${colors.primaryGrey};
  border-radius: 0.6rem;
  flex: 1 1 100%;
  margin: 2rem;
  min-height: 40rem;
  min-width: 30rem;
  padding: 2.4rem 3.5rem;

  @media ${device.tablet} {
    flex: 1 1 calc(33% - 4rem);
    max-width: 50rem;
    width: calc(33% - 4rem);
  }
`;

const StyledJobIcon = styled(JobIcon)`
  height: 2.4rem;
  width: 2.8rem;
  margin-right: 0.5rem;
  vertical-align: top;

  path {
    fill: ${colors.primaryBlue};
  }
`;

const StyledExperienceRole = styled.h4`
  color: ${colors.primaryPurple};
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 1.21;
  margin: 2.1rem 0 3rem;
  width: calc(100% - 2.8rem);

  &::after {
    background-color: ${colors.secondaryGrey};
    content: "";
    display: block;
    height: 0.1rem;
    margin-top: 3rem;
    max-width: 28rem;
    width: 100%;
  }
`;

const StyledJobRole = styled.span``;

const StyledExperienceCompany = styled.span`
  color: ${colors.primaryBlue};
  display: block;
  margin-top: 1.5rem;
`;

const StyledExperienceTechnologyHeading = styled.h5`
  color: ${colors.primaryPurple};
  font-size: 1.6rem;
  font-weight: 700;
`;

const StyledExperienceTechnologies = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledExperienceTechnology = styled.li`
  &::before {
    background: url(${Tick}) no-repeat;
    content: "";
    display: inline-block;
    height: 1.1rem;
    margin-right: 1.1rem;
    width: 1.1rem;
  }

  color: ${colors.darkGrey};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2;
`;

const Experience = ({ company, role, technologies }) => {
  return (
    <StyledExperience>
      <StyledExperienceRole>
        <StyledJobIcon />
        <StyledJobRole>{role} </StyledJobRole>
        <StyledExperienceCompany>@{company}</StyledExperienceCompany>
      </StyledExperienceRole>
      <StyledExperienceTechnologyHeading>
        Experience
      </StyledExperienceTechnologyHeading>
      <StyledExperienceTechnologies>
        {technologies &&
          technologies.length > 0 &&
          technologies.map(({ name }, index) => (
            <StyledExperienceTechnology key={`experience-${index}`}>
              {name}
            </StyledExperienceTechnology>
          ))}
      </StyledExperienceTechnologies>
    </StyledExperience>
  );
};

export default Experience;
