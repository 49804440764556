import React, { forwardRef } from "react";
import styled, { css } from "styled-components";

import colors from "../styles/colors";

const StyledInput = styled.input`
  background-color: transparent;
  border: 0.1rem solid ${colors.primaryWhite};
  border-radius: 3.3rem;
  color: ${colors.primaryWhite};
  font-size: 2.2rem;
  font-weight: 300;
  height: 6rem;
  padding: 2.3rem 3.5rem 2rem 2rem;

  &::placeholder {
    color: ${colors.primaryWhite};
    font-size: 2.3rem;
    font-weight: 300;
  }

  ${props =>
    props.error &&
    css`
      border-color: ${colors.primaryRed};
    `}
`;

const Input = forwardRef(
  ({ name, id, placeholder, type = "text", onChange, error }, ref) => (
    <StyledInput
      name={name && name}
      id={id && id}
      type={type}
      placeholder={placeholder && placeholder}
      onChange={onChange && onChange}
      ref={ref}
      error={error}
    />
  )
);

export default Input;
