import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import Experience from "./experience";

const SectionExperience = () => {
  const {
    allExperienceJson: { edges: experienceList },
  } = useStaticQuery(
    graphql`
      query ExperiencesFetch {
        allExperienceJson {
          edges {
            node {
              company
              id
              role
              technologies {
                name
              }
            }
          }
        }
      }
    `
  );

  const StyledExperienceList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  `;

  return (
    <StyledExperienceList>
      {experienceList &&
        experienceList.length > 0 &&
        experienceList.map(experience => {
          const { company, id, role, technologies } = experience.node;
          return (
            <Experience
              role={role}
              company={company}
              technologies={technologies}
              key={id}
            />
          );
        })}
    </StyledExperienceList>
  );
};

export default SectionExperience;
