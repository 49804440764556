import React from "react";
import styled, { css } from "styled-components";

import TextDecorator from "../components/text-decorator";

import colors from "../styles/colors";
import device from "../styles/media-queries";

const StyledSectionWrapper = styled.header`
  width: 100%;
`;

const StyledSectionHeading = styled.h3`
  color: ${colors.primaryPurple};
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 2.2;
  text-align: center;

  ${props =>
    props.inversedHeading &&
    css`
      color: ${colors.primaryWhite};
    `}

  @media ${device.tablet} {
    font-size: 3.9rem;
  }

  @media ${device.laptop} {
    font-size: 4.6rem;
  }
`;

const SectionHeading = ({ inversedHeading, name }) => {
  return (
    <StyledSectionWrapper>
      <StyledSectionHeading inversedHeading={inversedHeading}>
        {name}
      </StyledSectionHeading>
      <TextDecorator centered />
    </StyledSectionWrapper>
  );
};

export default SectionHeading;
